import React from 'react'
import './Loading.css'
function Loading() {
    return (
        <div className="loading">
            <span>Loading</span>
        </div>
    )
}

export default Loading
